import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from "react-oidc-context";
import reportWebVitals from './reportWebVitals';

const oidcConfig = {
  authority: "https://login.microsoftonline.com/c0ced471-202d-4d63-b963-19c9821d50c7/v2.0",
  client_id: "55a5dcd5-d2da-4c56-9dd1-d8dbb9687985",
  redirect_uri: "https://lpazureprod.j6dev.net",
  scope: "email openid profile https://govellum.com/launchpadapi/Employees.ReadWrite"
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
